import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/Docs.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ImagePair = makeShortcode("ImagePair");
const ImageTriplet = makeShortcode("ImageTriplet");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <img {...{
      "wide": "true",
      "src": "https://res.cloudinary.com/studiocollective/image/upload/v1708978016/Photography/Bombay%20%2724/000065980001_q9uvhm.jpg"
    }}></img>
    <h2>{`Bombay on film`}</h2>
    <p>{`Jan 2024・Leica CL 35mm`}</p>
    <br />
    <ImagePair src1="https://res.cloudinary.com/studiocollective/image/upload/v1708978120/Photography/Bombay%20%2724/000066010016_pi4pxa.jpg" src2="https://res.cloudinary.com/studiocollective/image/upload/v1708978080/Photography/Bombay%20%2724/000066010017_ldbzgw.jpg" mdxType="ImagePair" />
    <img {...{
      "wide": "true",
      "src": "https://res.cloudinary.com/studiocollective/image/upload/v1708978067/Photography/Bombay%20%2724/000066000024_qvr1i9.jpg"
    }}></img>
    <ImagePair src1="https://res.cloudinary.com/studiocollective/image/upload/v1708978077/Photography/Bombay%20%2724/000066010008_t2ydbk.jpg" src2="https://res.cloudinary.com/studiocollective/image/upload/v1708978037/Photography/Bombay%20%2724/000065980029_bryavl.jpg" mdxType="ImagePair" />
    <img {...{
      "wide": "true",
      "src": "https://res.cloudinary.com/studiocollective/image/upload/v1708978034/Photography/Bombay%20%2724/000065980023_mgpc4t.jpg"
    }}></img>
    <ImageTriplet src1="https://res.cloudinary.com/studiocollective/image/upload/v1708978074/Photography/Bombay%20%2724/000066010006_orszba.jpg" src2="https://res.cloudinary.com/studiocollective/image/upload/v1708978172/Photography/Bombay%20%2724/000066010010_yaokku.jpg" src3="https://res.cloudinary.com/studiocollective/image/upload/v1708978083/Photography/Bombay%20%2724/000066010028_hevera.jpg" mdxType="ImageTriplet" />
    <ImagePair src1="https://res.cloudinary.com/studiocollective/image/upload/v1708978058/Photography/Bombay%20%2724/000065990030_moddu9.jpg" src2="https://res.cloudinary.com/studiocollective/image/upload/v1708978052/Photography/Bombay%20%2724/000065990017_ztozut.jpg" mdxType="ImagePair" />
    <ImagePair src1="https://res.cloudinary.com/studiocollective/image/upload/v1708978064/Photography/Bombay%20%2724/000066000017_yfjk5n.jpg" src2="https://res.cloudinary.com/studiocollective/image/upload/v1708978061/Photography/Bombay%20%2724/000066000008_chslxb.jpg" mdxType="ImagePair" />
    <img {...{
      "wide": "true",
      "src": "https://res.cloudinary.com/studiocollective/image/upload/v1708978028/Photography/Bombay%20%2724/000065980017_u1w4b0.jpg"
    }}></img>
    <ImagePair src1="https://res.cloudinary.com/studiocollective/image/upload/v1708978089/Photography/Bombay%20%2724/000065990007_exy1ji.jpg" src2="https://res.cloudinary.com/studiocollective/image/upload/v1708978093/Photography/Bombay%20%2724/000066010035_vvqx7i.jpg" mdxType="ImagePair" />
    <ImageTriplet src1="https://res.cloudinary.com/studiocollective/image/upload/v1708978237/Photography/Bombay%20%2724/000065980020_dyych7.jpg" src3="https://res.cloudinary.com/studiocollective/image/upload/v1708978086/Photography/Bombay%20%2724/000066010037_ts0eoi.jpg" src2="https://res.cloudinary.com/studiocollective/image/upload/v1708978040/Photography/Bombay%20%2724/000065980033_sdr4nj.jpg" mdxType="ImageTriplet" />
    <img {...{
      "wide": "true",
      "src": "https://res.cloudinary.com/studiocollective/image/upload/v1708978019/Photography/Bombay%20%2724/000065980006_nz114r.jpg"
    }}></img>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      